
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

select{
 	option:first-child{
    	color: #ccc;
	}
 }

.head-back{
 	i{
 		font-size: 40px;
   	 	margin-top: 20px;
 	}
 }

.no-data{
	min-height: 300px;
    color: white;
    border: 1px solid white;

    h3{
    	text-align: center;
    	width: 100%;
    }
}

.height150{
	height: 150px;
}

.admin-button{
	padding: 6px;
    font-size: 15px;
    border: 1px white;
    color: white;
    border-radius: 5px;
    font-weight: bold;
}

.admin-button-s{
	margin-top: -6px;
	font-size: 15px;
    border: 1px white;
    color: white;
    border-radius: 5px;
    font-weight: bold;
	padding: 4px !important;
}

.bRed{
	background-color: red;
}

.bGreen{
	background-color: #22b417;;
}

.no_product h3{
    text-align: center;
    width: 100%;
}

.no_product{
	min-height: 300px;
	color: white;
	border: 1px solid white;
}

.adminVertical{
	margin-right: 30px !important;	
}

.no-stock-div{
	height: 100px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.not-in-stock{
	margin-top: 45px;
    font-size: 30px;
    color: white;
}

.avblProduct img{
	width: 30px;
}

.image-delete{
	position: absolute;
    right: 18px;
    font-size: 20px;
    color: red;
}

.product-loading{
	display: none;
}

.product-loading img{
	width: 20%;
	margin: auto;
	display: block;
}

.product-no-price-text-div{
    height: 100px;
    color: white;
    font-size: 12px;
}

.product-title-no-price{
    display: block !important;
    display: -webkit-box;
    margin: 0 auto;
    font-size: 18px;
    line-height: 1.4;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


#product-detailed-page{
  	#myCarousel{
	    margin: 10px 5px 0px 30px;
	}

	.title{
		margin-top: 5px;
    	font-weight: bold;
    	color: white;
    	font-size: 23px;
	}

	.sub-title{
	    margin-top: -5px;
    	font-size: 20px;
    	color: white;
   	 	font-weight: normal;
	}

	ul, li {
	   	margin:0;
	   	padding:0;
	  	list-style:none;
	  	line-height: 17px;
	  	color: #b7adb3;
	  	margin-right: 10px;   
	}

	li:before {
	   content: "* " 
	}

	.image-block{
		margin-top: 25px;
	}

	.image-block img{
		width: 65px;
    	margin: 0px 8px 0px 0px;
	}

	.buttons-div{
	    margin-top: 35px;
	}

	.console-buttons{
		width: 200px;
    	margin: auto;
	}

	.product-image-div{
		margin-left: 10px;
		
		img{
			display: block;
    		margin: auto;
    		max-width: 100%;
		}
	}

	.head-back{
		margin-left: -31px;
   		margin: 0px 15px 0px -20px;
	}

    .plus-icon2 {
        width: 60%;
    }

    .prod-qty-input2 {
        width: 100%;
        margin: 0px 0px 0px -10px;
    }


	@media screen and (max-width: 768px) {
	    .product-image-div img {
		    max-width: 60%;
		}

		.product-single-div{
			margin: 29px 20px -10px 20px;

			.title{
				 font-size: 18px;
			}

		}
	}
}

#cart-page{
	 margin-bottom: 20%;

	.tax-price{
		font-size: 20px !important; 
		margin: 0;
	}

	.delete-icon{
		display: none;
	}
}

#checkout_page{
	margin: 3% 4% 18% 4% !important;
	.address-sub-title{
	    margin: 30px 0px 30px 25px;
	    font-size: 23px;
	    color: #c3b2bd;
	    font-weight: normal;
	}

	.other-info{
		border: 1px solid #dedddd;
	   	min-height: 100px;
    	margin: 4px 0px 0px 0px;
	}

	.border-label{
		border-right: 1px solid #c80f72;
	    margin: 0px 0px 15px 0px;
	}

	.address-div-check .fa-arrow-right{
		font-size: 30px;
	    margin: 5px 0px 5px 0px;
	    color: #c80f72;
	}

	.address-text{
		line-height: 1
	}

	.address-div-check .fa-map-marker{
		font-size: 50px !important;
	    margin: 20px 0px 0px 6px;
	}

	.address-div-check .fa-address-book{
		font-size: 40px !important;
	    margin: 27px 0px 0px -5px;
	}

	.address-section label{
		font-size: 14px;
		color: black;
		margin-bottom: 0px;
	}

	.address-section{
		padding-left: 7px;
		padding-right: 7px;
	}
	.address-label{
		font-size: 10px;
    	padding: 0px 0px 5px 10px;
    	color: black;
	}

	.address-div-check{
		background-color: white;
		margin: 5px 40px 0px 40px;
	}

	.order-total hr{
		border: 1px solid #e387b8;
		margin-bottom: 0px;
	}

	.order-total{
		padding: 0px 15px 0px 15px;
		padding: 0px 15px 0px 15px;
	    color: black;
	    font-size: 15px;
	    font-weight: bold;
	}

	.fa-shopping-cart, .fa-home{
		font-size: 26px !important;
	}

	.order-block{
		margin: 5px 40px 0px 40px;
    	background-color: white;
    	font-size: 14px;
    	font-weight: normal !important;
	}

	tr td:first-child,
	tr th:first-child{
		padding-left: 30px;
	}

	th,td{
		padding: 5px 0px 5px 10px;
	}

	tbody tr:first-child td{
		padding-top: 10px;
	}
	.order-block table{
		width: 100%;
		color: black !important;
		padding: 5px 5px 5px 25px;
	}

	.order-block thead{
		color: white;
		background-color: #c80f72;
	}

	.no-address-section{
		span{
		    padding: 7px 5px 7px 5px;
		    display: block;
		    text-align: center;
		    margin: 27px 0px 16px 0px;
		    color: white;
		    background-color: #3979d9;
		}
	}
}


.image-upload > input{
    display: none;
}


.image-upload img
{
    width: 80px;
    cursor: pointer;
}

#profile-page, #add-product-page{
	margin-bottom: 20%;
 	padding-bottom: 20px;

	.image-upload > input{
	    display: none;
	}

	.image-upload img{
	    cursor: pointer;
	}

	.check-icon-files{
		text-align: center;

		i{
			font-size: 80px !important;
		}
	}

	.files-section{
		h3{
			font-size: 22px;
		    color: black;
		    text-align: center;
		    font-weight: bold;
		}
	}

	.profile-input-right{
		margin-right: 15px;
	    display: block;
	}

	.custom-file-upload {
	    border: 1px solid #ccc;
	    display: inline-block;
	    padding: 6px 12px;
	    cursor: pointer;
	    width: 90%;
	    background-color: #ececec;
	    color: #636b6f;
	    text-align: center;
	    font-size: 15px;
	    font-weight: normal;
	    margin-top: 15px;
	    cursor: pointer;
	    display: block;
	    margin-left: auto;
	    margin-right: auto;
	}

	input[type="file"] {
	    display: none;
	}

	ul{
		padding-left: 20px;
		margin: 0;
		color: black;
	}

	li .pdf-button{
		position: relative;
		left: -25px;
		color: #636b6f !important;
	}

	ul i{
		float: right;
		background-color: black;
	    color: white !important;
	    padding: 5px;
	    border-radius: 100%;
	}

	.check-icon{
		img{
			width:30px;
		}
	}

	.no-address{    
		font-size: 18px;
	    margin-top: 12px;
	    text-align: center;
	    display: block;
    }

    .left-border{
    	border-left: 1px solid #e387b8;
    }


    .ordersDiv{
    	margin-left: 10%;
    	font-size: 15px;
    	padding: 5px 0px 1px 2px;
    }

    .editCircleOrder{
    	bottom: -42px !important;
    }

}

#edit-address-page{
	input[type=checkbox] {
		margin-top: -7px;
	}
}

#search-products-page{
	 
	.no_product h3{
	    text-align: center;
	    width: 100%;
	}

	.no_product{
		min-height: 300px;
		color: white;
		border: 1px solid white;
	}
}

#page-search, #admin_page{

	.search-div input[type=text] {
	    width: 92%;
	    padding: 12px 20px;
	    margin: 8px 0;
	    display: inline-block;
	    border: 1px solid #ccc;
	    border-top-left-radius: 10px;
	    border-bottom-left-radius: 10px;
	    box-sizing: border-box;
	    height: 45px;
	    font-size: 21px;

	    margin-left: -35px;
	}

	.search-div i{
		font-size: 19px;
	    border-radius: 0px 10px 10px 0px;
	    padding-top: 4px;
	    color: white;
	    margin: 7px 0px 0px 0px;
	    background-color: #c21e76;
	    height: 47px;
	}

	input::-webkit-input-placeholder {
		color: #575757 !important;
	}
	 
	input:-moz-placeholder { /* Firefox 18- */
		color: #575757 !important;  
	}
	 
	input::-moz-placeholder {  /* Firefox 19+ */
		color: #575757 !important;  
	}
	 
	input:-ms-input-placeholder {  
		color: #575757 !important;  
	}

	.no-search{
		padding: 3% 1% 5% 1%;
	    color: #ffffff;
	    font-size: 18px;
	}
}

#admin_page, #profile-page{

	.admin-head{
	    color: white;
	    background-color: #c80f72;
	    font-size: 17px;
	    padding: 5px;
	}

	.admin-body label{
		font-size: 16px;
	}

	.admin-body{
		padding: 20px 0px 20px 0px;
		border-bottom: 1px solid #c80f72;
	}

	.admin-table{
		text-align: center;
		margin-top: -15px;
	}

	.admin-buttons{
		    padding: 30px 20px 10px 30px;
	}

	.add-product-div{
		margin: 30px 0px 0px 36px;

		button{
			background-color: #22b417;
    		font-weight: bold;
    		color: white;
    		padding: 5px 15px 5px 15px;
		}
	}

    .search-div input[type=text] {
       
        padding: 12px 20px !important;
        margin: -6px 0px 0px 1px !important;
        margin-left: 0px !important; 
    }

    .search-div{
        width: 90%;
    }

    .search-div i {
        margin: -7px 0px 0px 0px;
    }
}

#add-product-page{


}

footer{
	.footer-background{
		position: fixed;
	    left: 0;
	    bottom: 0;
        max-height: 120px !important;
	}

	.icon-footer img {
	    min-width: 36px !important;
	    width: 48%;
	    max-width: 60px !important;
	}
}

#products-page, #search-products-page{
	.prod-qty-input{
        height: 80px;     
        width: 60%;
        font-size: 40px;
        font-weight: bold;
        color: black;
        text-align: right; 
        padding-right: 20px;
    }

    .plus-icon {
        margin-left: 5px !important;
        margin-right: 2%;
        height: 80px;
        width: 15%;
        max-width: 40px;
        font-size: 45px;
    }

    .minus-icon{
        margin-left: 2%;
        height: 80px;
        width: 15%;
        max-width: 40px;
        font-size: 45px;
    }

    .plusDiv{
        margin: -2px 0px 0px -2px;

    }

    .add-cart-div {
        margin: 0px 0px 0px -15px;
        width: 102%;
    }

    .add-cart-div {
        padding: 25px 20px 25px 20px;
    }

    .arrow-div{
        display: block !important;
    }

}


@media screen and (min-width: 767px) {
    .cart-image{
        left:35px;
    }
}

@media screen and (max-width: 766px) {

	#category-filter{
		margin-top: 5px;
	}

    #products-page, #search-products-page{
        .prod-qty-input {
            height: 65px;
            width: 60%;
            font-size: 35px;
        }

        .plus-icon,.minus-icon {
            height: 65px;
            font-size: 40px;
        }

        .add-cart-div {
            font-size: 15px !important;
        }

        .add-cart-div {
            font-size: 20px;
            height: 65px !important;
            margin: 0px;
        }
    }

	.container .product-title {
	    max-width: initial !important;
	    height: auto !important;
	    margin: 0 auto;
	    font-size: 18px;

	}

    #products-page{
        .prod-qty-input{     
            width: 55%;
        }
    }
	.product-price {
	    font-size: 20px;
	    margin-left: 15px;
	}

	#page-search{
		.search-col{
			padding: 0px 30px 0px 30px;
		}
	}

	#checkout_page{
		.border-label{
			border: none;
			margin-bottom: 0px;
		}

		.address-div-check .fa-map-marker {
		    font-size: 40px !important;
		    margin: 15px 0px 0px 30px;
		}

		.right-arrow{
			margin-right: 20px;
		}

		.textarea-div{
			padding: 0px 15px 0px 15px;
			margin-bottom: 15px;
		}

		.other-info{
			width: 100%;
		}

		.address-label {
		    font-size: 12px;
		    padding: 15px 0px 0px 15px;
		    color: black;
		}
	}

	#profile-page{
		.left-border{
			border: none !important;
		}

		.profile-section{
			.height150{
				height: auto;
			}
		}

		.profile-logo-img{
			margin: 0px 20px 0px 20px;
		}

		.editCircleOrder{
			right: -20%;
		}

		.editCircle2{
		 	right: -10%;
		 	bottom: -56px;
		}

		.editCircle3{
			right: -10%;
    		bottom: -45px;
		}
	}

    #admin_page{
        .vertical-text{
            display: none;
        }
    }
}


@media screen and (max-width: 600px) {
     #admin_page{
        .admin-head {
            color: white;
            background-color: #c80f72;
            font-size: 14px;
            padding: 5px;
        }

        .admin-button {
            padding: 4px;
            font-size: 11px;
        }

        .admin-body label {
            font-size: 14px;
        }

        .order-title {
            font-size: 20px;
        }
    }

    .product-description{
        display: none;
    }
    
    #products-page, #search-products-page{

        .prod-qty-input {
            height: 50px;
            width: 60%;
            font-size: 25px;
        }

        .plus-icon, .minus-icon {
            height: 50px;
            font-size: 26px;
        }

        .add-cart-div {
            font-size: 20px;
            padding: 25px 10px 25px 10px;
            font-size: 12px !important;
            height: 50px !important;
        }

        .button-div{
            padding-right: 0;
        }

        .glyphicon-play {
            margin-left: -5px;
            font-size: 20px;
        }
    }


	.shop-body {
	    margin: 3% 0% 18% 0% !important;
	    min-height: 600px !important;
	}

	.shop-head {
		margin-bottom: 15px !important;
		h2{
			font-size: 20px;
		}
	}

	.products-back-div {
	    margin: 0px 25px 0px 25px !important;
	}

	.head-back i{
		font-size: 25px;
	}

	.order-button {
	    width: 70% !important;
	    font-size: 15px !important;
	    padding: 13px 0px 13px 0px !important;
	}

	.white-div {
		width: 200px !important;
	}

	#search-products-page{
		.vertical-text {
		    margin: -16px -43px -20px 0px;
		}
	}

	#cart-page{
		.container .prod-qty-input2 {
		    width: 47%;
		}

		.plus-icon2{
			width: 30px;
		}

		.products-div{
			 margin: 25px 6px 0px 6px;

		}
	}

	#profile-page{
		.orders-col{
			padding: 0 !important;
		}
		
		.ordersDiv{
			margin-left: 0;
		}
	}



	.container{

		 #circle {
	    	width: 55px;
	    	height: 55px;
	    }

    	.cart-image {
    		width: 33%;
    		margin: 0px 0px 0px 8px;
		}

		.arrow-right-cart {
		    border-top: 15px solid transparent;
		    border-bottom: 15px solid transparent;
		    border-right: 15px solid white;
		}

		.product-title{
			font-size: 11px !important;
		}

		.product-price {
		    font-size: 13px;
		}


		.product-text-div{
			padding: 0;
		}

		.product-image{
			height: 100% !important;
			width: 100% !important;
			max-height: 100px;
		}

		.circle-div{
			padding-right: 0;
		}

		.prod-qty-input {
		    height: 50%;
		    width: 95%;
		    font-size: 30px;
		}

		.plus-icon, .minus-icon {
		    font-size: 20px;
		}

		.arrow-div{
			display: none;
		}

		.logo-footer {
		    width: 68px;
		    margin: -11% 0% 0% 0%;
		 }

		.profile-sub-title {
		    font-size: 18px;

		    i {
			    font-size: 25px;
			}
		}
	}
}


@media screen and (max-width: 420px) {
	

    #admin_page{
        .order-col{
            padding-right: 5px;
            padding-left: 5px;
        }

        .profile-section {
            margin: 0px;
        }

        .admin-button {
            padding: 2px;
            font-size: 12px;
        }

        .admin-body label {
            font-size: 12px;
        }

        .admin-head, #profile-page .admin-head {
            font-size: 14px;
        }

        .order-title {
            margin: 20px 35px -7px 9px;
            font-size: 18px;
        }
    }

    #page-login, #register-page{
        .has-feedback .form-control {
             padding-right:0px;
        }

        .form-auth h1{
            font-size: 30px
        }

        .tax-section span {
            font-size: 16px;
        }

        input[type=checkbox] {
            width: 20px;
            height: 20px;
        }

        .col-tax{
            padding: 0;
        }
    }

    #products-page , #search-products-page{
        .plus-icon, .minus-icon {
            height: 40px;
            font-size: 14px;
        }

        .prod-qty-input {
            height: 40px;
            width: 60%;
            font-size: 25px;
        }

        .add-cart-div {
            font-size: 12px !important;
            height: 40px !important;
        }

        .button-div{
            padding-left: 10px;
        }
    }

	#edit-address-page{
		.profile-section {
	   		background-color: white;
	    	margin: 0px 15px 0px 15px;
		}

		.profile-sub-title {
	    	margin: 16px -1px 10px 0px;
	    }
	}

	#checkout_page{
		.line{
			font-size: 16px;
		}

		.profile-section {
		    margin: 0px 15px 0px 15px;
		    font-size: 12px;
		}
	}

	#page-search{

		.col-x{
			padding: 0;
		}

		.saved-search {
		    padding: 5px 15px 0px 15px;
		}

		.saved-search h2{
			font-size: 25px;
		}

		.search-div input[type=text] {
		    font-size: 16px;
		}

	}

	#search-products-page{
		#brand-filter, #category-filter{
			padding: 5px 0px 5px 6px !important;
		}

		#brand-filter{
			margin-top: 5px;
		}

		#category-filter{
			margin-bottom: 5px;
		}
	}

	#cart-page{
		.prod-qty-input2 {
		    height: 25px;
		    width: 40%;
		    font-size: 15px;
		    padding-right: 0;
	    }

	    .plus-icon2 {
		    font-size: 20px;
		    height: 25px !important;
		    width: 15%;

		    div{
		    	height: 25px;
    			margin-top: -6px;
    			display: block;
		    }
		}

		.product-title-c {
			font-size: 12px;
            margin-top: 5px;
		}

		.product-price-c{
			font-size: 14px;
		}

		.delete-div{
			padding: 0;
		}

		.total-c{
			font-size: 14px;
			margin: 20px 0px 0px 5px;
		}

		.total-price-c{
		    font-size: 14px !important;
		    margin: 0px 0px 0px 5px !important;
		}

		.product-div, .product-text-div, .delete-div, .cart-delete {
    		height: 80px;
    	}

    	.cart-delete{
    		font-size: 11px;
    		line-height: 80px;
    	}

    	.delete-icon{
    		display: block;
    		position: absolute;
		    right: 19px;
		    top: 20px;
		    color: white;
		    font-size: 25px;
    	}

    	.cart-image-d {
		    width: 19px;
		    height: 80px;
		}

		.grand-total {
		    font-size: 18px;
		    margin: 5px 6px 0px 6px;
		}

		.container, .tax-price {
    		font-size: 15px !important;
		}

	}

	#checkout_page{

		.order-block{
			margin: 5px 0px 0px 0px !important;
		}

		.profile-sub-title {
		    margin: 16px -1px 10px -10px;
		    font-size: 23px;
		}

		.order-total hr{
			display: none;
		}

		.address-div-check {
		    margin: 5px 0px 0px 0px;
		}

		.address-sub-title {
		    margin: 30px 0px 5px -14px;
		    font-size: 18px;
		}
	}

	#profile-page{
		.profile-input-div{
			padding: 0;
		}

		.profile-section {
		    margin: 0px 15px 0px 15px;
		}

		.profile-sub-title {
		    margin: 16px -1px 10px 0px;
		}

		.ordersDiv {
			margin-left: 0;
		    padding: 5px 0px 1px 0px;
		}

		.custom-file-upload{
			width: 100% !important;
		}

		.upload-left-col, .upload-right-col{
			padding: 0;
		}

		.editCircle {
		    width: 50px;
		    height: 50px;
		    font-size: 18px;
		}
	}

	.container{
		#circle {
	    	width: 45px;
	    	height: 45px;
		}

		.circle-block{
			padding: 0;
		}

		.circle-div{
			padding-right: 15px !important;
		}


		.vertical-text {
		    margin: -12px -28px -20px 0px;
		    font-size: 14px;
		}

		.arrow-right-prod {
		    border-top: 19px solid transparent;
		    border-bottom: 20px solid transparent;
		    border-right: 20px solid #c80f72;
		}

		.products-back-div{
			margin: 0 !important;

			h3{
				font-size: 15px;
			}
		}

		.add-cart-div {
		    font-size: 11px;
		    width: 95px;
		}

		.icon-footer img {
		    min-width: 25px !important;
		   
		}

		.footer-auth, footer-no-auth{
			min-height: 55px;
		}

		.numberCircle {
		    min-width: 15px;
		    height: 15px;
		    font-size: 9px;
		}

		.logo-footer {
		    width: 45px;
		    margin: -7% 0% 0% 0%;
		}
	}
}

@media screen and (max-width: 340px) {
     #products-page, #search-products-page{
        .plus-icon, .minus-icon {
            height: 30px;
            font-size: 13px;
        }

        .prod-qty-input {
            height: 30px;
            width: 60%;
            font-size: 20px;
        }

        .add-cart-div {
            font-size: 12px !important;
            height: 30px !important;
        }

        .button-div{
            padding-left: 10px;
        }

        .col-add-product{
            padding: 0;
        }

        .add-cart-div{
            margin-right: 5px;
        }

        .add-cart-div {
            font-size: 10px !important;
            line-height: 1;
        }
    }
}

#page-shop-by{
	
	@media screen and (max-width: 599px) {
	    .text-shop-f{
	    	font-size: 15px;
	    }

	    .order-button {
		    width: 60%;
		    font-size: 16px;
		    padding: 15px 0px 15px 0px;
		}

	}

	@media screen and (max-width: 420px) {
	    .text-shop-f{
	    	font-size: 14px;
	    	margin-left: 20px;
	    	margin-right: 20px;
	    }
	}
}


@media (min-width:1025px)  {
    .logo-footer{
        margin: -7% 0% 0% 0% !important;
    }

    footer {
        position: initial !important;
        margin-top: -7% !important;
    }

    .footer-background {
        position: absolute !important;
    }
}
